import { defineAsyncComponent } from 'vue'
import { CrudRoute, MenuItem } from '!/router'

/**
 * create routes (with grouping)
 */

const dashRoutes = [
  CrudRoute.buildRoute('dashboard')
    .setPageComponents(() => import('@/pages/index/IndexPage.vue'))
    .getData()
]
const cacheRoutes = [
  CrudRoute.buildRoute(
    'cache',
    () => import('@/pages/tools/cache/IndexPage.vue'),
    'Cache',
    'Cache maintenance',
    'ic:baseline-cached'
  ).getData()
]
const rewardRoutes = [
  ...CrudRoute.buildRoutes(
    'player-rankings-rewards',
    () => import('@/pages/rewards/player-rankings/IndexPage.vue'),
    () => import('@/pages/rewards/player-rankings/DetailsPage.vue'),
    'Player ranking rewards',
    'Player ranking reward',
    'arcticons:rewards',
    'staticdata.PlayerRankingRewardsEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'league-rewards',
    () => import('@/pages/rewards/league-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/league-rewards/DetailsPage.vue'),
    'League rewards',
    'League reward',
    'arcticons:rewards',
    'staticdata.LeagueRewardsEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'facebook-rewards',
    () => import('@/pages/rewards/facebook-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/facebook-rewards/DetailsPage.vue'),
    'Facebook rewards',
    'Facebook reward',
    'arcticons:rewards',
    'staticdata.FacebookFriendsRewardEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'daily-login-rewards',
    () => import('@/pages/rewards/daily-login-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/daily-login-rewards/DetailsPage.vue'),
    'Daily login rewards',
    'Daily login reward',
    'arcticons:rewards',
    'staticdata.DailyLoginRewardsEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'deep-links',
    () => import('@/pages/rewards/deep-links/IndexPage.vue'),
    () => import('@/pages/rewards/deep-links/DetailsPage.vue'),
    'Deep links',
    'Deep link',
    'mdi:link-variant',
    'staticdata.FriendCodeEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'boss-event-rewards',
    () => import('@/pages/rewards/boss-event-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/boss-event-rewards/DetailsPage.vue'),
    'Boss event rewards',
    'Boss event reward',
    'arcticons:rewards',
    'staticdata.BossEventRewardEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'chest-rush-rewards',
    () => import('@/pages/rewards/chest-rush-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/chest-rush-rewards/DetailsPage.vue'),
    'Chest rush rewards',
    'Chest rush reward',
    'arcticons:rewards',
    'staticdata.BossEventChestRushRewardEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'guild-rush-rewards',
    () => import('@/pages/rewards/guild-rush-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/guild-rush-rewards/DetailsPage.vue'),
    'Guild rush rewards',
    'Guild rush reward',
    'arcticons:rewards',
    'staticdata.BossEventGuildRushRewardEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'trophies',
    () => import('@/pages/rewards/trophies/IndexPage.vue'),
    () => import('@/pages/rewards/trophies/DetailsPage.vue'),
    'Trophies',
    'Trophy',
    'arcticons:rewards',
    'staticdata.TrophyEntity',
    defineAsyncComponent(() => import('@/components/selectors/TrophySelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'tournament-rewards',
    () => import('@/pages/rewards/tournament-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/tournament-rewards/DetailsPage.vue'),
    'Tournament rewards',
    'Tournament reward',
    'arcticons:rewards',
    'staticdata.TournamentRewardEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gift-code-rewards',
    () => import('@/pages/rewards/gift-code-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/gift-code-rewards/DetailsPage.vue'),
    'Gift code rewards',
    'Gift code reward',
    'arcticons:rewards',
    'staticdata.GiftCodeRewardsEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'achievement-rewards',
    () => import('@/pages/rewards/achievement-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/achievement-rewards/DetailsPage.vue'),
    'Achievement rewards',
    'Achievement reward',
    'arcticons:rewards',
    'staticdata.AchievementRewardsEntity',
    defineAsyncComponent(() => import('@/components/selectors/AchievementRewardSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'hit-three-event-rewards',
    () => import('@/pages/rewards/hit-three-event-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/hit-three-event-rewards/DetailsPage.vue'),
    'Hit three event rewards',
    'Hit three event reward',
    'arcticons:rewards',
    'staticdata.HitThreeEventRewardEntity',
    defineAsyncComponent(() => import('@/components/selectors/HitThreeEventRewardSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'lottery-event-rewards',
    () => import('@/pages/rewards/lottery-event-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/lottery-event-rewards/DetailsPage.vue'),
    'Lottery event rewards',
    'Lottery event reward',
    'arcticons:rewards',
    'staticdata.LotteryEventRewardEntity',
    defineAsyncComponent(() => import('@/components/selectors/LotteryEventRewardSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'boss-event-global-rewards',
    () => import('@/pages/rewards/boss-event-global-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/boss-event-global-rewards/DetailsPage.vue'),
    'Boss event global rewards',
    'Boss event global reward',
    'arcticons:rewards',
    'staticdata.BossEventGlobalRewardEntity',
    defineAsyncComponent(() => import('@/components/selectors/BossEventGlobalRewardSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'hit-three-event-global-rewards',
    () => import('@/pages/rewards/hit-three-event-global-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/hit-three-event-global-rewards/DetailsPage.vue'),
    'Hit three event global rewards',
    'Hit three event global reward',
    'arcticons:rewards',
    'staticdata.HitThreeEventGlobalRewardEntity',
    defineAsyncComponent(() => import('@/components/selectors/HitThreeEventGlobalRewardSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'lottery-event-global-rewards',
    () => import('@/pages/rewards/lottery-event-global-rewards/IndexPage.vue'),
    () => import('@/pages/rewards/lottery-event-global-rewards/DetailsPage.vue'),
    'Lottery event global rewards',
    'Lottery event global reward',
    'arcticons:rewards',
    'staticdata.LotteryEventGlobalRewardEntity',
    defineAsyncComponent(() => import('@/components/selectors/LotteryEventGlobalRewardSelector.vue'))
  ).getData()
]
if (import.meta.env.VITE_GAME === 'gang') {
  rewardRoutes.push(
    ...CrudRoute.buildRoutes(
      'arithmetic-run-rewards',
      () => import('@/pages/rewards/arithmetic-run-rewards/IndexPage.vue'),
      () => import('@/pages/rewards/arithmetic-run-rewards/DetailsPage.vue'),
      'Arithmetic run rewards',
      'Arithmetic run reward',
      'mdi:run',
      'staticdata.ArithmeticRunRewardsEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'card-deck-rewards',
      () => import('@/pages/rewards/card-deck-rewards/IndexPage.vue'),
      () => import('@/pages/rewards/card-deck-rewards/DetailsPage.vue'),
      'Card deck rewards',
      'Card deck reward',
      'mdi:cards',
      'staticdata.CardDeckRewardsEntity',
      defineAsyncComponent(() => import('@/components/selectors/CardDeckRewardSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'card-event-rewards',
      () => import('@/pages/rewards/card-event-rewards/IndexPage.vue'),
      () => import('@/pages/rewards/card-event-rewards/DetailsPage.vue'),
      'Card event rewards',
      'Card event reward',
      'mdi:cards',
      'staticdata.CardEventRewardEntity',
      defineAsyncComponent(() => import('@/components/selectors/CardEventRewardSelector.vue'))
    ).getData()
  )
} else {
  // snr
  rewardRoutes.push(
    ...CrudRoute.buildRoutes(
      'spin-milestone-rewards',
      () => import('@/pages/rewards/spin-milestone-rewards/IndexPage.vue'),
      () => import('@/pages/rewards/spin-milestone-rewards/DetailsPage.vue'),
      'Spin milestone rewards',
      'Spin milestone reward',
      'arcticons:rewards',
      'staticdata.SpinMilestoneRewardsEntity'
    ).getData()
  )
  rewardRoutes.push(
      ...CrudRoute.buildRoutes(
          'wheel-milestone-rewards',
          () => import('@/pages/rewards/wheel-milestone-rewards/IndexPage.vue'),
          () => import('@/pages/rewards/wheel-milestone-rewards/DetailsPage.vue'),
          'Wheel milestone rewards',
          'Wheel milestone reward',
          'arcticons:rewards',
          'staticdata.WheelMilestoneRewardsEntity'
      ).getData()
  )
}
const cosmeticsRoutes = [
  ...CrudRoute.buildRoutes(
    'skins',
    () => import('@/pages/cosmetics/skins/IndexPage.vue'),
    () => import('@/pages/cosmetics/skins/DetailsPage.vue'),
    'Skins',
    'Skin',
    'majesticons:t-shirt-line',
    'staticdata.SkinEntity',
    defineAsyncComponent(() => import('@/components/selectors/SkinSelector.vue'))
  )
    .setMenuLabel('Skins & items')
    .getData(),
  ...CrudRoute.buildRoutes(
    'mount-types',
    () => import('@/pages/cosmetics/mount-types/IndexPage.vue'),
    () => import('@/pages/cosmetics/mount-types/DetailsPage.vue'),
    'Mount types',
    'Mount type',
    'mdi:cow',
    'staticdata.MountTypeEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'custom-dices',
    () => import('@/pages/cosmetics/custom-dices/IndexPage.vue'),
    () => import('@/pages/cosmetics/custom-dices/DetailsPage.vue'),
    'Custom dices',
    'Custom dice',
    'fontisto:laboratory',
    'staticdata.CustomDiceEntity',
    defineAsyncComponent(() => import('@/components/selectors/CustomDiceSelector.vue'))
  ).getData()
]
if (import.meta.env.VITE_GAME === 'gang') {
  cosmeticsRoutes.push(
    ...CrudRoute.buildRoutes(
      'skin-groups',
      () => import('@/pages/cosmetics/skins-groups/IndexPage.vue'),
      () => import('@/pages/cosmetics/skins-groups/DetailsPage.vue'),
      'Skin groups',
      'Skin group',
      'material-symbols:table-rows-outline',
      'staticdata.SkinGroupEntity',
      defineAsyncComponent(() => import('@/components/selectors/SkinGroupSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'cards',
      () => import('@/pages/cosmetics/cards/IndexPage.vue'),
      () => import('@/pages/cosmetics/cards/DetailsPage.vue'),
      'Cards',
      'Card',
      'mdi:cards',
      'staticdata.SkinEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'decks',
      () => import('@/pages/cosmetics/decks/IndexPage.vue'),
      () => import('@/pages/cosmetics/decks/DetailsPage.vue'),
      'Decks',
      'Deck',
      'mdi:cards',
      'staticdata.SkinGroupEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'event-decks',
      () => import('@/pages/cosmetics/event-decks/IndexPage.vue'),
      () => import('@/pages/cosmetics/event-decks/DetailsPage.vue'),
      'Event decks',
      'Event deck',
      'mdi:cards',
      'staticdata.SkinGroupEntity'
    ).getData()
  )
} else {
  // snr
  cosmeticsRoutes.push(
    ...CrudRoute.buildRoutes(
      'forge-items',
      () => import('@/pages/cosmetics/forge-items/IndexPage.vue'),
      () => import('@/pages/cosmetics/forge-items/DetailsPage.vue'),
      'Forge items',
      'Forge item',
      'fontisto:laboratory',
      'staticdata.SkinEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'forge-receipts',
      () => import('@/pages/cosmetics/forge-receipts/IndexPage.vue'),
      () => import('@/pages/cosmetics/forge-receipts/DetailsPage.vue'),
      'Forge receipts',
      'Forge receipt',
      'fontisto:laboratory',
      'staticdata.ForgeReceiptEntity',
      defineAsyncComponent(() => import('@/components/selectors/ForgeReceiptSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'stones',
      () => import('@/pages/cosmetics/stones/IndexPage.vue'),
      () => import('@/pages/cosmetics/stones/DetailsPage.vue'),
      'Stones',
      'Stone',
      'mdi:diamond-stone',
      'staticdata.SkinEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'cosmetic-effects',
      () => import('@/pages/cosmetics/cosmetic-effects/IndexPage.vue'),
      () => import('@/pages/cosmetics/cosmetic-effects/DetailsPage.vue'),
      'Cosmetic effects',
      'Cosmetic effect',
      'fontisto:laboratory',
      'staticdata.SkinEntity'
    ).getData()
  )
}
const bossEventsRoutes = [
  ...CrudRoute.buildRoutes(
    'boss-events',
    () => import('@/pages/boss-events/IndexPage.vue'),
    () => import('@/pages/boss-events/DetailsPage.vue'),
    'Boss events',
    'Boss event',
    'ri:sword-fill',
    'staticdata.BossEventEntity',
    defineAsyncComponent(() => import('@/components/selectors/BossEventSelector.vue'))
  ).getData()
]
const eventsRoutes = [
  ...CrudRoute.buildRoutes(
    'hit-three-events',
    () => import('@/pages/events/hit-three-events/IndexPage.vue'),
    () => import('@/pages/events/hit-three-events/DetailsPage.vue'),
    'Hit three events',
    'Hit three event',
    'mdi:cow',
    'staticdata.HitThreeEventEntity',
    defineAsyncComponent(() => import('@/components/selectors/HitThreeEventSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'unlimited-roll-events',
    () => import('@/pages/events/unlimited-roll-events/IndexPage.vue'),
    () => import('@/pages/events/unlimited-roll-events/DetailsPage.vue'),
    'Unlimited roll events',
    'Unlimited roll event',
    'mdi:cow',
    'staticdata.UnlimitedRollEventEntity',
    defineAsyncComponent(() => import('@/components/selectors/UnlimitedRollEventSelector.vue'))
  ).getData()
]
if (import.meta.env.VITE_GAME === 'gang') {
  eventsRoutes.push(
    ...CrudRoute.buildRoutes(
      'card-events',
      () => import('@/pages/events/card-events/IndexPage.vue'),
      () => import('@/pages/events/card-events/DetailsPage.vue'),
      'Card events',
      'Card event',
      'mdi:cards',
      'staticdata.CardEventEntity',
      defineAsyncComponent(() => import('@/components/selectors/CardEventSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'card-exchange-events',
      () => import('@/pages/events/card-exchange-events/IndexPage.vue'),
      () => import('@/pages/events/card-exchange-events/DetailsPage.vue'),
      'Card exchange events',
      'Card exchange event',
      'mdi:cards',
      'staticdata.CardExchangeEventEntity',
      defineAsyncComponent(() => import('@/components/selectors/CardExchangeEventSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'lottery-events',
      () => import('@/pages/events/lottery-events/IndexPage.vue'),
      () => import('@/pages/events/lottery-events/DetailsPage.vue'),
      'Lottery events',
      'Lottery event',
      'mdi:cow',
      'staticdata.LotteryEventEntity',
      defineAsyncComponent(() => import('@/components/selectors/LotteryEventSelector.vue'))
    ).getData(),
    ...CrudRoute.buildRoutes(
      'arithmetic-runs',
      () => import('@/pages/events/arithmetic-runs/IndexPage.vue'),
      () => import('@/pages/events/arithmetic-runs/DetailsPage.vue'),
      'Arithmetic runs',
      'Arithmetic run',
      'mdi:run',
      'staticdata.ArithmeticRunEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'arithmetic-run-obstacles',
      () => import('@/pages/events/arithmetic-run-obstacles/IndexPage.vue'),
      () => import('@/pages/events/arithmetic-run-obstacles/DetailsPage.vue'),
      'Arithmetic run obstacles',
      'Arithmetic run obstacle',
      'mdi:run',
      'staticdata.ArithmeticRunObstacleEntity',
      defineAsyncComponent(() => import('@/components/selectors/ArithmeticRunObstacleSelector.vue'))
    ).getData()
  )
}

const pveEventsRoutes = [
  ...CrudRoute.buildRoutes(
    'pve-challenge-settings',
    () => import('@/pages/events/pve-challenge-settings/IndexPage.vue'),
    () => import('@/pages/events/pve-challenge-settings/DetailsPage.vue'),
    'Pve Challenge Settings',
    'Pve Challenge Setting',
    'arcticons:settings',
    'staticdata.PveChallengeSettingsEntity',
    defineAsyncComponent(() => import('@/components/selectors/PveChallengeSettingsSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'pve-challenges',
    () => import('@/pages/events/pve-challenges/IndexPage.vue'),
    () => import('@/pages/events/pve-challenges/DetailsPage.vue'),
    'Pve Challenges',
    'Pve Challenge',
    'mdi:home',
    'staticdata.PveChallengeEntity',
    defineAsyncComponent(() => import('@/components/selectors/PveChallengeSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'pve-challenge-events',
    () => import('@/pages/events/pve-challenge-events/IndexPage.vue'),
    () => import('@/pages/events/pve-challenge-events/DetailsPage.vue'),
    'Pve Challenge Events',
    'Pve Challenge Event',
    'mdi:home',
    'staticdata.PveChallengeEventEntity',
    defineAsyncComponent(() => import('@/components/selectors/PveChallengeEventSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'pve-challenge-history',
    () => import('@/pages/events/pve-challenge-history/IndexPage.vue'),
    () => null,
    'Pve history',
    'Pve history',
    'mdi:home',
    'gameplay.BattleLogEntity'
  ).getData()
]
const tournamentsRoutes = [
  ...CrudRoute.buildRoutes(
    'tournaments',
    () => import('@/pages/tournaments/IndexPage.vue'),
    () => import('@/pages/tournaments/DetailsPage.vue'),
    'Tournaments',
    'Tournament',
    'ph:tree-structure',
    'staticdata.TournamentEntity',
    defineAsyncComponent(() => import('@/components/selectors/TournamentSelector.vue'))
  ).getData()
]
const chestsRoutes = [
  ...CrudRoute.buildRoutes(
    'chests',
    () => import('@/pages/chests/chests/IndexPage.vue'),
    () => import('@/pages/chests/chests/DetailsPage.vue'),
    'Chests',
    'Chest',
    'game-icons:open-chest',
    'staticdata.ChestEntity',
    defineAsyncComponent(() => import('@/components/selectors/ChestSelector.vue'))
  ).getData()
]
if (import.meta.env.VITE_GAME === 'gang') {
  chestsRoutes.push(
    ...CrudRoute.buildRoutes(
      'packs',
      () => import('@/pages/chests/packs/IndexPage.vue'),
      () => import('@/pages/chests/packs/DetailsPage.vue'),
      'Packs',
      'Pack',
      'mdi:cards',
      'staticdata.ChestEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'event-packs',
      () => import('@/pages/chests/event-packs/IndexPage.vue'),
      () => import('@/pages/chests/event-packs/DetailsPage.vue'),
      'Event packs',
      'Event pack',
      'mdi:cards',
      'staticdata.ChestEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'lotteries',
      () => import('@/pages/chests/lotteries/IndexPage.vue'),
      () => import('@/pages/chests/lotteries/DetailsPage.vue'),
      'Lotteries',
      'Lottery',
      'game-icons:open-chest',
      'staticdata.ChestEntity'
    ).getData()
  )
} else {
  chestsRoutes.push(
    ...CrudRoute.buildRoutes(
      'spins',
      () => import('@/pages/chests/spins/IndexPage.vue'),
      () => import('@/pages/chests/spins/DetailsPage.vue'),
      'Spins',
      'Spin',
      'fa6-solid:spinner',
      'staticdata.ChestEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'wheels',
      () => import('@/pages/chests/wheels/IndexPage.vue'),
      () => import('@/pages/chests/wheels/DetailsPage.vue'),
      'Wheels',
      'Wheel',
      'fa6-solid:spinner',
      'staticdata.ChestEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'new-player-chests',
      () => import('@/pages/chests/new-player-chests/IndexPage.vue'),
      () => import('@/pages/chests/new-player-chests/DetailsPage.vue'),
      'New player chests',
      'New player chest',
      'game-icons:open-chest',
      'staticdata.NewPlayerChestsEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'mystery-chests',
      () => import('@/pages/chests/mystery-chests/IndexPage.vue'),
      () => import('@/pages/chests/mystery-chests/DetailsPage.vue'),
      'Mystery Chests',
      'Mystery Chest',
      'game-icons:open-chest',
      'staticdata.ChestEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'duplicate-shops',
      () => import('@/pages/chests/duplicate-shops/IndexPage.vue'),
      () => import('@/pages/chests/duplicate-shops/DetailsPage.vue'),
      'Duplicate Shops',
      'Duplicate Shop',
      'game-icons:open-chest',
      'staticdata.DuplicateShopEntity'
    ).getData(),
    ...CrudRoute.buildRoutes(
      'lootboxes',
      () => import('@/pages/chests/lootboxes/IndexPage.vue'),
      () => import('@/pages/chests/lootboxes/DetailsPage.vue'),
      'Lootboxes',
      'Lootbox',
      'game-icons:open-chest',
      'staticdata.LootboxEntity',
      defineAsyncComponent(() => import('@/components/selectors/LootboxSelector.vue'))
    ).getData()
  )
}
const achievementsRoutes = [
  ...CrudRoute.buildRoutes(
    'achievements',
    () => import('@/pages/achievements/achievements/IndexPage.vue'),
    () => import('@/pages/achievements/achievements/DetailsPage.vue'),
    'Achievements',
    'Achievement',
    'uil:game-structure',
    'staticdata.AchievementEntity',
    defineAsyncComponent(() => import('@/components/selectors/AchievementSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'achievement-groups',
    () => import('@/pages/achievements/achievement-groups/IndexPage.vue'),
    () => import('@/pages/achievements/achievement-groups/DetailsPage.vue'),
    'Achievement groups',
    'Achievement group',
    'uil:game-structure',
    'staticdata.AchievementsGroupEntity',
    defineAsyncComponent(() => import('@/components/selectors/AchievementGroupSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'achievement-type-configs',
    () => import('@/pages/achievements/achievement-type-configs/IndexPage.vue'),
    () => import('@/pages/achievements/achievement-type-configs/DetailsPage.vue'),
    'Achievement type configs',
    'Achievement type config',
    'uil:game-structure',
    'staticdata.AchievementTypeConfigEntity'
  ).getData()
]
const offersRoutes = [
  ...CrudRoute.buildRoutes(
    'offers',
    () => import('@/pages/offers/offers/IndexPage.vue'),
    () => import('@/pages/offers/offers/DetailsPage.vue'),
    'Offers',
    'Offer',
    'ic:outline-attach-money',
    'staticdata.OfferEntity',
    defineAsyncComponent(() => import('@/components/selectors/OfferSelector.vue'))
  ).getData(),
  ...CrudRoute.buildRoutes(
    'multi-offer-groups',
    () => import('@/pages/offers/multi-offer-groups/IndexPage.vue'),
    () => import('@/pages/offers/multi-offer-groups/DetailsPage.vue'),
    'Multi offer groups',
    'Multi offer group',
    'ic:outline-attach-money',
    'staticdata.MultiOfferGroupEntity',
    defineAsyncComponent(() => import('@/components/selectors/MultiOfferGroupsSelector.vue'))
  ).getData()
]
const energyPacksRoutes = [
  ...CrudRoute.buildRoutes(
    'energy-packs',
    () => import('@/pages/energy-packs/IndexPage.vue'),
    () => import('@/pages/energy-packs/DetailsPage.vue'),
    'Energy packs',
    'Energy pack',
    'mdi:cart-arrow-down',
    'staticdata.ShopEntity' // TODO CHECK
  ).getData()
]
const seasonRoutes = [
  ...CrudRoute.buildRoutes(
    'seasons',
    () => import('@/pages/seasons/IndexPage.vue'),
    () => import('@/pages/seasons/DetailsPage.vue'),
    'Seasons',
    'Season',
    'material-symbols:calendar-month-outline',
    'staticdata.SeasonEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'season-calendars',
    () => import('@/pages/season-calendars/IndexPage.vue'),
    () => import('@/pages/season-calendars/DetailsPage.vue'),
    'Season calendars',
    'Season calendar',
    'mdi:calendar-time',
    'staticdata.SeasonCalendarEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'season-passes',
    () => import('@/pages/season-passes/IndexPage.vue'),
    () => import('@/pages/season-passes/DetailsPage.vue'),
    'Season passes',
    'Season pass',
    'material-symbols:calendar-month-outline',
    'staticdata.SeasonPassEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'season-migration',
    () => import('@/pages/season-migration/IndexPage.vue'),
    () => import('@/pages/season-migration/DetailsPage.vue'),
    'Migration settings',
    'Migration settings',
    'mdi:people',
    'staticdata.MigrationSettingsEntity'
  ).getData()

]

const settingsRoutes = [
  ...CrudRoute.buildRoutes(
    'game-settings',
    () => import('@/pages/settings/game-settings/IndexPage.vue'),
    () => import('@/pages/settings/game-settings/DetailsPage.vue'),
    'Game settings',
    'Game setting',
    'arcticons:settings',
    'staticdata.GameSettingsEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'change-logs',
    () => import('@/pages/settings/change-logs/IndexPage.vue'),
    () => import('@/pages/settings/change-logs/DetailsPage.vue'),
    'Changelogs',
    'Changelog',
    'arcticons:settings',
    'admin.ChangelogEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'guild-banners',
    () => import('@/pages/settings/guild-banners/IndexPage.vue'),
    () => import('@/pages/settings/guild-banners/DetailsPage.vue'),
    'Guild banners',
    'Guild banner',
    'arcticons:settings',
    'staticdata.BannerEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'device-flags',
    () => import('@/pages/settings/device-flags/IndexPage.vue'),
    () => import('@/pages/settings/device-flags/DetailsPage.vue'),
    'Device flags',
    'Device flag',
    'ph:flag',
    'global.DeviceFlagsEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'guild-search-options',
    () => import('@/pages/settings/guild-search-options/IndexPage.vue'),
    () => import('@/pages/settings/guild-search-options/DetailsPage.vue'),
    'Guild search options',
    'Guild search option',
    'arcticons:settings',
    'staticdata.GuildSearchOptionEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'guild-banner-configs',
    () => import('@/pages/settings/guild-banner-configs/IndexPage.vue'),
    () => import('@/pages/settings/guild-banner-configs/DetailsPage.vue'),
    'Guild banner configs',
    'Guild banner config',
    'arcticons:settings',
    'staticdata.GuildBannerConfigEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'incubator-messages',
    () => import('@/pages/settings/incubator-messages/IndexPage.vue'),
    () => import('@/pages/settings/incubator-messages/DetailsPage.vue'),
    'Incubator messages',
    'Incubator messages',
    'arcticons:settings',
    'staticdata.IncubatorMessageEntity'
  ).getData()
]
const divisionSettingsRoutes = [
  ...CrudRoute.buildRoutes(
    'division-levels',
    () => import('@/pages/division-settings/division-levels/IndexPage.vue'),
    () => import('@/pages/division-settings/division-levels/DetailsPage.vue'),
    'Division levels',
    'Division level',
    'arcticons:settings',
    'staticdata.DivisionLevelEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'battle-rewards',
    () => import('@/pages/division-settings/battle-rewards/IndexPage.vue'),
    () => import('@/pages/division-settings/battle-rewards/DetailsPage.vue'),
    'Battle rewards',
    'Battle reward',
    'arcticons:settings',
    'staticdata.DivisionBattleRewardsEntity'
  ).getData()
]
const toolsRoutes = [
  ...CrudRoute.buildRoutes(
    'template-global-messages',
    () => import('@/pages/tools/template-global-messages/IndexPage.vue'),
    () => import('@/pages/tools/template-global-messages/DetailsPage.vue'),
    'Template global messages',
    'Template global message',
    'material-symbols:mail-outline',
    'staticdata.GlobalMessageTemplateEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'global-messages',
    () => import('@/pages/tools/global-messages/IndexPage.vue'),
    () => import('@/pages/tools/global-messages/DetailsPage.vue'),
    'Global messages',
    'Global message',
    'material-symbols:mail-outline',
    'staticdata.GlobalMessageEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'personal-messages',
    () => import('@/pages/tools/personal-messages/IndexPage.vue'),
    () => import('@/pages/tools/personal-messages/DetailsPage.vue'),
    'Personal messages',
    'Personal message',
    'material-symbols:mail-outline',
    'admin.PersonalMessageEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'group-notifications',
    () => import('@/pages/tools/group-notifications/IndexPage.vue'),
    () => import('@/pages/tools/group-notifications/DetailsPage.vue'),
    'Group notifications',
    'Group notification',
    'material-symbols:notification-important-outline',
    'staticdata.GroupNotificationEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'console-commands',
    () => import('@/pages/tools/console-commands/IndexPage.vue'),
    () => import('@/pages/tools/console-commands/DetailsPage.vue'),
    'Console Commands',
    'Console Command',
    'material-symbols:mail-outline',
    'admin.ConsoleCommandEntity'
  ).getData()
]
const gamePlayRoutes = [
  CrudRoute.buildRoute(
    'gameplay-payment-logs',
    () => import('@/pages/gameplay/payment-logs/IndexPage.vue'),
    'Payment logs',
    'Payment logs',
    'majesticons:money-hand-line',
    'gameplay.PaymentLogEntity'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-divisions',
    () => import('@/pages/gameplay/divisions/IndexPage.vue'),
    'Divisions',
    'Divisions',
    'ic:outline-grid-view'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gameplay-guild-list',
    () => import('@/pages/gameplay/guild-list/IndexPage.vue'),
    () => import('@/pages/gameplay/guild-list/DetailsPage.vue'),
    'Guild lists',
    'Guild',
    'mdi:list-box-outline',
    'gameplay.GuildEntity'
  )
    .setSelector(false)
    .getData(),
  CrudRoute.buildRoute(
    'gameplay-new-players',
    () => import('@/pages/gameplay/new-players/IndexPage.vue'),
    'New players 24h',
    'New players 24h',
    'mdi:person-multiple-add-outline'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-welcome-players',
    () => import('@/pages/gameplay/welcome-players/IndexPage.vue'),
    'Welcome players list',
    'Welcome players list',
    'mdi:person-multiple-add-outline'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-season-pts-ranking',
    () => import('@/pages/gameplay/season-pts-ranking/IndexPage.vue'),
    'Player season points ranking',
    'Season points ranking',
    'material-symbols:bubble-chart-outline'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-season-pts-groups',
    () => import('@/pages/gameplay/season-pts-groups/IndexPage.vue'),
    'Season points groups',
    'Season points groups',
    'material-symbols:bubble-chart'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-guild-search-test',
    () => import('@/pages/gameplay/guild-search-test/IndexPage.vue'),
    'Guild search test',
    'Guild search test',
    'ic:outline-manage-search'
  )
    .setQuery({
      c_sea: {
        zone: 'Europe',
        country: 'PL',
        level: '4',
        phone: 'iPhone',
        avg_revenue: '25'
      }
    })
    .getData(),
  CrudRoute.buildRoute(
    'promo-energy-hack-log',
    () => import('@/pages/gameplay/promo-energy-hack-log/IndexPage.vue'),
    'Promo Energy Hack Log',
    'Promo Energy Hack Log',
    'material-symbols:bubble-chart',
    'gameplay.PromoEnergyHackLogEntity'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-player-device-ids',
    () => import('@/pages/gameplay/player-device-ids/IndexPage.vue'),
    'Player Device Ids',
    'Player Device Ids',
    'material-symbols:smartphone'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gameplay-players',
    () => import('@/pages/gameplay/players/IndexPage.vue'),
    () => import('@/pages/gameplay/players/DetailsPage.vue'),
    'Players',
    'Player',
    'mdi:person-multiple',
    'gameplay.PlayerEntity'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-autoinvite-preview',
    () => import('@/pages/gameplay/autoinvite-preview/IndexPage.vue'),
    'Autoinvite preview',
    'Autoinvite preview',
    'ic:twotone-auto-mode'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-autoinvite-logs',
    () => import('@/pages/gameplay/autoinvite-logs/IndexPage.vue'),
    'Autoinvite logs on prod',
    'Autoinvite logs',
    'icon-park-outline:upload-logs'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-chat-preview',
    () => import('@/pages/gameplay/chat/IndexPage.vue'),
    'Chat preview',
    'Chat preview',
    'material-symbols:chat-outline'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gameplay-tournaments',
    () => import('@/pages/gameplay/tournaments/IndexPage.vue'),
    () => import('@/pages/gameplay/tournaments/DetailsPage.vue'),
    'Tournaments history',
    'Tournament history',
    'mdi:tournament',
    'gameplay.TournamentInitializedEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gameplay-boss-events',
    () => import('@/pages/gameplay/boss-events/IndexPage.vue'),
    () => import('@/pages/gameplay/boss-events/DetailsPage.vue'),
    'Boss events history',
    'Boss event history',
    'charm:sword',
    'gameplay.BossEventInitializedEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gameplay-hit-three-events',
    () => import('@/pages/gameplay/hit-three-events/IndexPage.vue'),
    () => import('@/pages/gameplay/hit-three-events/DetailsPage.vue'),
    'Hit Three Event history',
    'Hit Three Event history',
    'mdi:cow',
    'gameplay.HitThreeEventInitEntity'
  ).getData(),
  ...CrudRoute.buildRoutes(
    'gameplay-lottery-events',
    () => import('@/pages/gameplay/lottery-events/IndexPage.vue'),
    () => import('@/pages/gameplay/lottery-events/DetailsPage.vue'),
    'Lottery Event history',
    'Lottery Event history',
    'mdi:cow',
    'gameplay.LotteryStateEntity'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-skin-usage',
    () => import('@/pages/gameplay/skin-usage/IndexPage.vue'),
    'Skin usage',
    'Skin usage',
    'majesticons:t-shirt-line'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-avatars-list',
    () => import('@/pages/gameplay/avatars-list/IndexPage.vue'),
    'Avatars list',
    'Avatars list',
    'mdi:people'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-season-calendar',
    () => import('@/pages/gameplay/season-calendar/IndexPage.vue'),
    'Season day calendar',
    'Season day calendar',
    'mdi:calendar'
  ).getData(),
  CrudRoute.buildRoute(
    'gameplay-player-resources-logs',
    () => import('@/pages/gameplay/player-resources-logs/IndexPage.vue'),
    'Player Resources Logs',
    'Player Resources Logs',
    'mdi:person-multiple-add-outline'
  ).getData()
]

export default () => {
  const items = []

  items.push(
    ...[
      MenuItem.build('dashboard', dashRoutes).getData(),
      MenuItem.build('cache-btn')
        .setMenuItemComponent(defineAsyncComponent(() => import('@/pages/tools/cache/MenuItem.vue')))
        .getData(),
      MenuItem.build('cache', cacheRoutes).setIsSubMenu(false).getData(),
      MenuItem.build('gameplay', gamePlayRoutes, 'mdi:gamepad-variant-outline', 'Gameplay stats').getData(),
      MenuItem.build('rewards', rewardRoutes, 'arcticons:rewards', 'Rewards').setCssClass('stroke-2').getData(),
      MenuItem.build('cosmetics', cosmeticsRoutes, 'majesticons:t-shirt-line', 'Cosmetics').getData(),
      MenuItem.build('boss-events', bossEventsRoutes, 'ri:sword-fill', 'Boss events').setIsSubMenu(false).getData(),
      MenuItem.build('events', eventsRoutes, 'mdi:cards', 'Events').getData()
    ]
  )

  if (import.meta.env.VITE_GAME === 'gang') {
    items.push(MenuItem.build('pve-events', pveEventsRoutes, 'mdi:home', 'Pve events').getData())
  }

  items.push(
    ...[
      MenuItem.build('tournaments', tournamentsRoutes, 'ph:tree-structure', 'Tournaments').setIsSubMenu(false).getData(),
      MenuItem.build(
        'chests',
        chestsRoutes,
        'game-icons:open-chest',
        import.meta.env.VITE_GAME === 'gang' ? 'Packs' : 'Chests'
      ).getData(),
      MenuItem.build('achievements', achievementsRoutes, 'uil:game-structure', 'Achievements').getData(),
      MenuItem.build('offers', offersRoutes, 'ic:outline-attach-money', 'Offers').getData(),
      MenuItem.build('energy-packs', energyPacksRoutes, 'mdi:cart-arrow-down', 'Energy packs').setIsSubMenu(false).getData(),
      MenuItem.build('seasons', seasonRoutes, 'mdi:calendar-time', 'Seasons').getData(),
      MenuItem.build('settings', settingsRoutes).setPriority(0).getData(),
      MenuItem.build('division-settings', divisionSettingsRoutes, 'arcticons:settings', 'Division settings')
        .setCssClass('stroke-2')
        .getData(),
      MenuItem.build('tools', toolsRoutes).setPriority(0).getData()
    ]
  )

  return items
}
